import { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { globalHistory } from '@reach/router'

const useBreadCrumb = breadCrumbItems => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  )
  useEffect(() => {
    const itemListElement = []
    breadCrumbItems.forEach((item, idx) => {
      const link = item.link || globalHistory.location.pathname
      itemListElement.push({
        '@type': 'ListItem',
        position: `${idx + 1}`,
        name: `${item.title}`,
        item: `${site.siteMetadata.siteUrl}${link}`,
      })
    })

    const scriptContent = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: itemListElement,
    }

    const scriptTag = document.createElement('script')
    scriptTag.type = 'application/ld+json'
    scriptTag.innerHTML = JSON.stringify(scriptContent)
    document.head.appendChild(scriptTag)

    return () => {
      document.head.removeChild(scriptTag)
    }
  }, [breadCrumbItems, site.siteMetadata.siteUrl])
}

export default useBreadCrumb
