import React from 'react'
import Layout from '../../components/layout'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import Image from '../../components/Lendis/Image'
import Header from '../../components/Header'
import BreadCrumb from '../../components/Share/BreadCrumb'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Referenzen',
  },
]

const referencePage = () => {
  const back =
    process.env.CLOUDINARY_URL + '/f_auto/website/0b_1920x704_darker_Coworking_qzdmeu.jpg'

  return (
    <Layout>
      <Header page="reference" />
      <Menu />
      <div
        className="big-background-image pb-8 bg-center bg-lendis-black bg-no-repeat bg-top lg:pb-40 pt-40 px-3"
        style={{ backgroundImage: `url(${back})` }}
      >
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">Referenzen</h1>
          <p className="font-semibold lg:text-2xl mb-10 mb-5 px-8 text-xl ">
            500+ Kunden vertrauen Lendis – entdecken Sie ihre Büros
          </p>
        </div>
        <div className="bg-white container mx-auto lg:px-10 ">
          <BreadCrumb breadCrumbItems={breadcrumb} />
        </div>
        <div className="bg-white container mx-auto pt-16">
          <div className="bg-white md:pb-16">
            <div className="md:flex  justify-center px-3 lg:px-16">
              <div className="flex flex-wrap justify-center w-full md:w-1/2 lg:mr-6 md:mr-1">
                <div className="my-1 lg:my-6">
                  <a
                    href={process.env.MAGAZINE_LINK + 'yilu-mietet-bueromoebel-bei-lendis'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="image-banner-link relative">
                      <div className="relative">
                        <Image src="website/references/image22_prhm4z.png" noChange alt="yilu" />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-8 ml-12">
                        <h5 className="text-2xl text-white max-w-xs">
                          Yilu – Die smarte Reiseplattform
                        </h5>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="my-1 lg:my-6">
                  <a
                    href={
                      process.env.MAGAZINE_LINK +
                      'proptech-start-up-intertempi-customer-success-story'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="image-banner-link relative">
                      <div className="relative">
                        <Image
                          src="website/references/image24_w9z7jn.png"
                          noChange
                          alt="intertempi"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-8 ml-12">
                        <h5 className="text-2xl text-white max-w-xs">
                          intertempi – creating flexible workspace
                        </h5>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="flex flex-wrap justify-center w-full md:w-1/2 lg:ml-6 md:ml-1">
                <div className="my-1 lg:my-6">
                  <a
                    href={process.env.MAGAZINE_LINK + 'organgery-ein-oekosystem-fuer-start-ups'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="image-banner-link relative">
                      <div className="relative">
                        <Image
                          src="website/references/image23_r6vd5o.png"
                          noChange
                          alt="orangery"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-8 ml-12">
                        <h5 className="text-2xl text-white max-w-xs">Orangery Coworking</h5>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="my-1 lg:my-6">
                  <a
                    href={process.env.MAGAZINE_LINK + 'coworking-space-ufer-berlin'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="image-banner-link relative">
                      <div className="relative">
                        <Image
                          src="website/references/image25_mgcnx7.png"
                          noChange
                          alt="coworking"
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 mb-8 ml-12">
                        <h5 className="text-2xl text-white max-w-xs">UFER Kreuzberg co-working</h5>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default referencePage
