import React from 'react'
import classnames from 'classnames'
import { Link } from 'gatsby'
import useBreadCrumb from '../../hooks/BreadCrumbScript'

const BreadCrumb = ({ breadCrumbItems, textPage = false, absolute = false }) => {
  useBreadCrumb(breadCrumbItems)
  return (
    <div
      className={`container mx-auto bg-white w-full relative ${classnames({
        'sm:mx-0 sm:w-1/2': absolute,
      })}`}
    >
      <div className="z-10 absolute left-0 mt-10 px-10 w-10/12 w-full text-xs text-gray-600">
        <div
          className={`h-20 block ${classnames({
            '-ml-4 lg:ml-4': textPage,
          })}`}
        >
          <ol className="flex flex-no-wrap overflow-x-auto">
            {breadCrumbItems.map((item, idx) => {
              console.log()
              return (
                <li
                  key={item.title}
                  className={`align-middle flex flex-no-wrap whitespace-no-wrap ${classnames({
                    'mr-2': idx + 1 !== breadCrumbItems.length,
                  })}`}
                >
                  {item.link ? (
                    <Link className="hover:text-lendis-main" to={item.link}>
                      {item.title}
                    </Link>
                  ) : (
                    <span className="cursor-default">{item.title}</span>
                  )}
                  {idx + 1 !== breadCrumbItems.length && <span className="ml-2">></span>}
                </li>
              )
            })}
          </ol>
        </div>
      </div>
    </div>
  )
}
export default BreadCrumb
